<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>게시판</sub-title>
                <div class="board_list">
                    <div class="hd">
                        <span class="hd bd_number">번호</span>
                        <span class="hd bd_title">제목</span>
                        <span class="hd bd_author">작성자</span>
                        <span class="hd bd_time">시간</span>
                    </div>
                    <div v-for="(item,index) in boardList" :key="index">
                        <div class="bd" v-if="item.type === sportsConst.BOARD_TYPE_FREE_BOARD">
                            <span class="bd_number">{{item.id}}</span>
                            <span class="bd_title">
                                  <router-link :to="{path:'/board_detail/' + item.id}"
                                               tag="a">{{item.title}} <i class="fa fa-comment-o text-green" v-if="item.commentQuantity > 0"> {{item.commentQuantity}}</i></router-link>
                                  &nbsp;<i class="fa fa-file-excel-o text-skyblue" v-if="item.betIds !== 'empty'"></i>
                                </span>
                            <span class="bd_author">{{item.user.nickname}}</span>
                            <span class="bd_time">{{item.createTime|datef('MM-DD')}}</span>
                        </div>
                    </div>
                </div>
                <!--페이지-->
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"
                            v-if="boardList.length > 0"></pagination>

                <!--글쓰기-->
                <div class="board_list">
                    <board-writer :bet-ids="betIds" :from="from"
                                  @saveBoardSuccess="saveBoardSuccess"></board-writer>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import Pagination from "../../components/pagenation/Pagination";
    import BoardWriter from "../../components/BoardWriter";
    import {getBoardList, getFreeBoard} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";

    export default {
        name: "Board",
        components: {
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, BoardWriter, Pagination, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                from: 0,//게시판에서 게시글 작성
                pageNum: 1,
                pageSize: 20,
                total: 1,
                orderBy: '',
                search: {},
                boardList: [],
                betIds: []
            }
        },
        methods: {
            initBoardList() {
                getFreeBoard(this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.boardList = res.data.data
                        this.boardList.forEach(b => {
                            b.createTime = this.$moment(b.createTime, 'YYYY-MM-DD HH:mm').format('MM/DD HH:mm');
                        })
                    }

                })
            },
            //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
            saveBoardSuccess() {
                this.initBoardList()
            },
            pageChange(page) {
                this.pageNum = page
                this.initBoardList()
            },
        },
        created() {
            this.initBoardList();
        }

    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .board_list span {
        overflow: hidden;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 12px !important;
    }

    .board_list div {
        border-bottom: 1px solid #2d2e35;
    }

    .board_list div:last-child {
        border-bottom: 0;
    }

    .board_list .hd {
        color: #f19f09;
        font-weight: bold;
        --border-bottom: 1px solid #1c1c25;
    }

    .board_list .notice span {
        color: #f19f09;
    }


    .bd_number {
        width: 10%;
    }

    .bd_title {
        width: 55%;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .bd_title a {
        text-align: left;
        color: #ffffff;
    }


    .bd_author {
        width: 20%;
    }

    .bd_time {
        width: 15%;
        text-align: right;
    }

    @media screen and (max-width: 800px) {
        .board_list span {
            font-size: 12px !important;
        }

        .bd_number {
            display: none !important;
        }

        .bd_title {
            width: 50%;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            text-align: left !important;
            box-sizing: border-box;
            padding-left: 4px;
        }

        .bd_author {
            width: 25%;
        }

        .bd_time {
            width: 25%;
            text-align: right;
        }
    }
</style>